import { v4 } from "uuid";
import { Salutation } from "./Customer";

export class Maschinenring {
    id: string;
    archived: boolean;
    name: string;
    contactPerson: MaschinenringContactPerson;

    constructor(initialValues?: Partial<Maschinenring>) {
        this.id = initialValues?.id ?? v4();
        this.archived = initialValues?.archived ?? false;
        this.name = initialValues?.name ?? "";
        this.contactPerson = initialValues?.contactPerson ?? createInitialMaschinenringContactPerson();
    }
}

export type MaschinenringContactPerson = {
    salutation: Salutation | null;
    firstName: string;
    lastName: string;
    mail: string;
    phone: string;
    mobile: string;
};

export function createInitialMaschinenringContactPerson(): MaschinenringContactPerson {
    return {
        salutation: null,
        firstName: "",
        lastName: "",
        mail: "",
        phone: "",
        mobile: "",
    };
}
